module.exports = {
  siteTitle: 'Code Good Works', // <title>
  manifestName: 'Code Good Works',
  manifestShortName: 'C.G.Works', // max 12 characters
  manifestStartUrl: 'https://codegoodworks.dev',
  manifestBackgroundColor: '#1e408b',
  manifestThemeColor: '#1e408b',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Yuci Gou',
  subHeading: 'Lead Consultant Developer',
  // social
  socialLinks: [
    {
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/yucigou',
      label: 'github',
    },
    {
      icon: 'fa-stack-overflow',
      name: 'Stack Overflow',
      url: 'https://stackoverflow.com/users/2700356/yuci',
      label: 'stack-overflow',
    },
    {
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/yuci-gou-75743710b/',
      label: 'linkedin',
    },
    {
      icon: 'fa-envelope-o',
      name: 'Email',
      url: 'mailto:yuci@codegoodworks.dev',
      label: 'email',
    },
  ],
};
