import React from 'react';
import 'typeface-roboto';

import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import CardMedia from '@material-ui/core/CardMedia';

import { withStyles } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';

import Layout from '../components/Layout';
import Scroll from '../components/Scroll';

import config from '../../config';
import Footer from '../components/Footer';
import screen from '../assets/images/screen.png';
import skillset from '../assets/images/skillset.png';
import aws from '../assets/images/aws-developer.png';
import awsSap from '../assets/images/aws-sap.png';
import ebi from '../assets/images/ebi.png';
import york from '../assets/images/york.png';
import covid19 from '../assets/images/covid19-tracker.jpg';
import codegoodworks from '../assets/images/codegoodworks-512x512.png';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    height: '48px',
    width: '48px',
  },
  link: {
    cursor: 'pointer',
  },
  dialog: {
    maxWidth: '1200px',
    margin: 'auto',
  },
});

const useStyles = makeStyles(styles);

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const IndexPage = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Layout>
      <header id="header">
        <div className="content">
          <h1>{config.heading}</h1>
          <p>{config.subHeading}</p>
          <p>
            <a
              href="https://www.green-custard.com/"
              target="_blank"
              rel="noreferrer"
            >
              <strong>Green Custard Ltd</strong>
            </a>
          </p>
          <ul className="actions">
            <li>
              <Scroll type="id" element="one">
                <a href="#one" className="button icon fa-chevron-down">
                  Learn More
                </a>
              </Scroll>
            </li>
          </ul>
        </div>
        <div className="image phone">
          <div className="top" />
          <div className="inner">
            <img src={screen} alt="Yuci in Athens" />
          </div>
        </div>
      </header>

      <section id="one" className="wrapper style2 special">
        <header>
          <h2>A professional AWS solutions architect and developer.</h2>
        </header>
      </section>

      <section id="two" className="wrapper">
        <div className="inner alt">
          <section className="spotlight">
            <div className="image">
              <a
                href="https://covid19-tracker.codegoodworks.dev/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={covid19} alt="Covid-19 Tracker App Logo" />
              </a>
            </div>
            <div className="content">
              <h3>Personal Project in 2020</h3>
              <h4>
                <a
                  href="https://covid19-tracker.codegoodworks.dev/"
                  target="_blank"
                  rel="noreferrer"
                >
                  COVID-19 Tracker
                </a>
              </h4>
              <p>
                COVID-19 Tracker is developed mainly to help find the daily
                COVID-19 cases by a given region or country.
              </p>
              <p>
                This app also shows the spread of COVID-19 on a world map, so as
                to give a bird's eye view on the pandemic globally.
              </p>
            </div>
          </section>
          <section className="spotlight">
            <div className="image">
              <Link
                component="a"
                className={classes.link}
                variant="body2"
                onClick={handleOpen}
              >
                <img src={skillset} alt="Skill Set Badges" />
              </Link>
            </div>
            <Dialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
              className={classes.dialog}
              fullScreen={true}
            >
              <DialogTitle
                id="customized-dialog-title"
                onClose={handleClose}
              ></DialogTitle>
              <CardMedia
                component="img"
                alt="Skill Set Badges"
                image={skillset}
                title="Skill Set Badges"
                style={{ maxWidth: '1110px', margin: 'auto' }}
              />
            </Dialog>
            <div className="content">
              <h3>Skill Set</h3>
              <p>
                Expertise in AWS cloud solutions architecting and serverless
                development with Node.js in TypeScript
              </p>
            </div>
          </section>
          <section className="spotlight">
            <div className="image">
              <a
                href="https://www.youracclaim.com/badges/bd6b6ca5-4983-4112-991d-7fa8b9349259"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={aws}
                  alt="AWS Certified Developer - Associate Badge"
                />
              </a>
            </div>
            <div className="image">
              <a
                href="https://www.credly.com/badges/6e0a81c5-47ad-4a0b-8842-a16725db5958/public_url"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={awsSap}
                  alt="AWS Certified Solutions Architect - Professional Badge"
                />
              </a>
            </div>
            <div className="content">
              <h3>Certification</h3>
              <h4>
                <a
                  href="https://www.credly.com/badges/6e0a81c5-47ad-4a0b-8842-a16725db5958/public_url"
                  target="_blank"
                  rel="noreferrer"
                >
                  AWS Certified Solutions Architect - Professional
                </a>
              </h4>
              <p>Issued January 2021 &middot; Expired January 2024</p>
            </div>
          </section>

          <section className="spotlight">
            <div className="image">
              <a
                href="https://codegoodworks.dev/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={codegoodworks} alt="Code Good Works Logo" />
              </a>
            </div>
            <div className="content">
              <h3>
                <a
                  href="https://codegoodworks.dev/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Freelance Cloud Developer, Code Good Works Ltd
                </a>
              </h3>
              <p>01 July 2020 - 31 March 2021</p>
              <p>
                Code Good Works aims to architect and code good works. It was
                inspired by those who labour in dire hardship.
              </p>
            </div>
          </section>

          <section className="spotlight">
            <div className="image">
              <a href="https://www.ebi.ac.uk/" target="_blank" rel="noreferrer">
                <img src={ebi} alt="EMBL-EBI Logo" />
              </a>
            </div>
            <div className="content">
              <h3>
                <a
                  href="https://www.ebi.ac.uk/about/people/yuci-gou"
                  target="_blank"
                  rel="noreferrer"
                >
                  Senior Full-stack Developer, EMBL-EBI
                </a>
              </h3>
              <p>01 July 2011 - 30 JUNE 2020</p>
              <p>
                Co-architected and developed{' '}
                <a
                  href="https://plus.europepmc.org"
                  target="_blank"
                  rel="noreferrer"
                >
                  Europe PMC plus manuscript submission system
                </a>{' '}
                through good teamwork and collaboration with many other
                organizations in{' '}
                <a
                  href="https://pubsweet.coko.foundation/"
                  target="_blank"
                  rel="noreferrer"
                >
                  the PubSweet open source community
                </a>{' '}
                (React.js, Node.js, GraphQL, PostgreSQL, Kubernetes, and GitLab
                CI)
              </p>
              <p>
                Initial maintainer of the web application for{' '}
                <a
                  href="https://europepmc.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Europe PMC
                </a>{' '}
                (Vue.js, Java EE, Spring, Hibernate, and Oracle)
              </p>
            </div>
          </section>

          <section className="spotlight">
            <div className="image">
              <a
                href="https://www.york.ac.uk/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={york} alt="University of York Logo" />
              </a>
            </div>
            <div className="content">
              <h3>Education</h3>
              <h4>
                <a
                  href="https://www.york.ac.uk/electronic-engineering/postgraduate/research_degrees/msc_research/"
                  target="_blank"
                  rel="noreferrer"
                >
                  MSc by Research in Electronics, University of York
                </a>
              </h4>
              <p>October 2003 - April 2005</p>
              <p>
                <a
                  href="https://ieeexplore.ieee.org/document/1705944"
                  target="_blank"
                  rel="noreferrer"
                >
                  MSc research on mobile communications focused on seamless
                  inter-networking of wireless LAN and cellular networks.
                </a>
              </p>
            </div>
          </section>
        </div>
      </section>

      <Footer />
    </Layout>
  );
};

export default IndexPage;
